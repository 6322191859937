// @flow

/* Component that shows short description for episodes and
   makes the (long) description unfoldable in place */

import React from 'react';

type Props = {
  description: string,
  shortDescription: string,
  showShortDescription: boolean,
}

type State = {
  showShortDescription: boolean,
  description: string,
}

class VideoDescription extends React.Component<Props, State> {
  state: State;

  constructor(props: Props): void {
    super(props);
    this.state = {
      showShortDescription: this.props.showShortDescription,
      description: this.props.shortDescription || this.props.description
    };
  }

  unfoldDescription = (): void => {
    if (this.state.showShortDescription) {
      this.setState({
        showShortDescription: false,
        description: this.props.description
      });
    }
  }

  renderDescription = (): ?any =>
    <p>{this.state.description}</p>

  render(): React$Element<'div'> {
    return (
      <div>
        {this.renderDescription()}
        {this.state.showShortDescription &&
          <div className="u-centered u-margin-top">
            <button className="btn btn--grey" onClick={this.unfoldDescription}>
              Meer informatie
            </button>
          </div>}
      </div>
    );
  }
}

export default VideoDescription;
