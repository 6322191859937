/* @flow
 *
 * Sends a form's data using XHR and evaluates the returned js code.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'xhr-form',
  init: (el) => {
    el.on('submit', (e) => {
      const buttonEl = el.find('[data-type=button]');
      buttonEl.prop('disabled', true);
      const form = e.target;
      $.ajax({
        type: 'POST',
        url: `${form.getAttribute('action')}.js`,
        data: new FormData(form),
        processData: false,
        contentType: false,
        dataType: 'script',
        success: () => { buttonEl.prop('disabled', false); }
      });
      return false;
    });
  }
});
