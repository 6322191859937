// @flow
import registerBehavior from '../../utils/registerBehavior';

const LOCAL_STORAGE_KEY = 'hideAndroidAppBanner';

function persistVisibility(): void {
  localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
}

function isHidden(): boolean {
  return localStorage.getItem(LOCAL_STORAGE_KEY) === 'true';
}

function isAndroid(): boolean {
  return navigator.userAgent.match(/Android/i) !== null;
}

registerBehavior({
  name: 'android-app-banner',
  init: (el) => {
    if (!isAndroid() || isHidden()) return;
    el.removeClass('is-hidden');

    el.find('[data-type=btn]').click(() => {
      el.addClass('is-hidden');
      persistVisibility();
    });
  }
});
