import React from 'react';

const Voted = () => {
  return (
    <div className="l-content">
      <h1 className="heading">Je stem telt bijna mee ...</h1>
      <div className="u-margin-top">
        Check je mailbox op een mail van Zapp en klik op de knop om je stem te bevestigen. 
      </div>
      <div className="u-margin-top">
      Let op: het kan zijn dat deze mail in een andere map terechtkomt. Bijvoorbeeld in 'Spam', 'Ongewenst' of 'Reclame'. 
      </div>
    </div>
  );
};

export default Voted;
