/* @flow
 *
 * Expose jQuery to window. The cookie module  and jQuery UJS needs a global
 * jQuery. The cookie module additionally needs the size() method, which was
 * available on older versions of jQuery.
 */

import npmJQuery from 'jquery';

npmJQuery.fn.extend({
  size(): void {
    return this.length;
  }
});

if (!window.jQuery || !window.$) {
  window.$ = npmJQuery;
  window.jQuery = npmJQuery;
}
