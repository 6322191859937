/* @flow
 *
 * Adds click handler to elements with toggle behavior. When clicked it
 * searches for other elements via [data-toggle=NAME] where name is defined
 * on the toggle behavior element via data-target=NAME.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'toggle',
  init: (el) => {
    el.on('click', () => {
      const targetName = el.attr('data-target');
      const target = $(`[data-toggle=${targetName}]`);
      const input = $(`[data-focus=${targetName}]`);
      target.toggleClass('is-hidden');
      input.focus();
      if (el.attr('data-toggle-scroll') && !target.hasClass('is-hidden')) {
        $('html, body').animate({ scrollTop: target.offset().top }, 1000);
      }
    });
  }
});
