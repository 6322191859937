// @flow

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

type State = {
  containerEl: any,
  btnEl: any,
  path: string,
  totalCount: number,
}

function loadedItems(containerEl: any): number {
  return containerEl.children().length;
}

function addOffsetToPath(path: string, offset: number): string {
  return `${path + (path.split('?')[1] ? '&' : '?')}offset=${offset}`;
}

function toggleBtn(state: State): void {
  if (loadedItems(state.containerEl) >= state.totalCount) {
    state.btnEl.hide();
  } else {
    state.btnEl.prop('disabled', false);
  }
}

function loadItems(state: State): void {
  state.btnEl.prop('disabled', true);
  const path = addOffsetToPath(state.path, loadedItems(state.containerEl));
  $.ajax({
    url: path,
    dataType: 'html',
    headers: { 'X-Pagination': 'true' },
    success: (html) => {
      state.containerEl.append(html);
      toggleBtn(state);
    }
  });
}

function getState(el: any): State {
  return {
    containerEl: el.find('[data-type=container]'),
    btnEl: el.find('[data-type=btn-load]'),
    path: el.data('path'),
    totalCount: el.data('count')
  };
}

function initPagination(el: any): void {
  const state = getState(el);
  toggleBtn(state);
  state.btnEl.on('click', () => {
    loadItems(state);
  });
}

registerBehavior({
  name: 'pagination',
  init: initPagination
});
