/* @flow
 *
 * Replace the content of the specified selector with the result of an xhr.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'replace-content',
  init: (el) => {
    el.on('ajax:success', (e, xhr) => {
      $(el.data('selector')).html(xhr);
    });
  }
});
