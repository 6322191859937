// @flow

import React from 'react';
import AwardsSteps from './AwardsSteps';
import Intro from './Intro';
import Confirm from './Confirm';
import Category from './Category';
import PlayerModal from '../Player/Modal';
import type { AwardsEvent as AwardsEventType } from './types';

type PageComponents = {
  [name: string]: any
};

const PAGE_COMPONENTS: PageComponents = {
  Intro,
  Confirm,
  Category
};

const AwardsEvent = (props: AwardsEventType) => {
  const page = props.pages[props.currentPageIndex];
  const PageComponent = PAGE_COMPONENTS[page.template || 'Category'];

  return (
    <div>
      <PlayerModal
        mid={props.playerModalMid}
        playerTokenPayload={props.playerModalPlayerToken}
        onClose={() => props.handleToggleModal()}
      />
      <AwardsSteps
        pages={props.pages}
        currentPageIndex={props.currentPageIndex}
        maxAllowedPageIndex={Object.keys(props.selection).length + 1}
        handleGoToPage={props.handleGoToPage}
        usedEmail={props.usedEmail}
      />
      <div className="u-border-top">
        <div className="l-content u-margin-top">
          <PageComponent
            awardsEvent={props}
            page={page}
          />
        </div>
      </div>
    </div>
  );
};

export default AwardsEvent;
