/* @flow
 *
 * Use a swiper for e.g. fragments
 * Documentation: http://idangero.us/swiper/api/
 */

import Swiper from 'swiper';
import registerBehavior from '../../utils/registerBehavior';

const PAGINATION_CLASS = '.swiper-pagination';

registerBehavior({
  name: 'caroussel',
  init: (el) => {
    // eslint-disable-next-line no-new
    new Swiper(el, {
      initialSlide: el.data('initialSlide') || 0,
      slidesPerView: el.data('slides') || 4,
      spaceBetween: 30,
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      pagination: el.data('desktop-bullets') ? PAGINATION_CLASS : null,
      breakpoints: {
        // eslint-disable-next-line quote-props
        '1024': {
          slidesPerView: el.data('slides-m') || 2,
          spaceBetween: 20
        },
        // eslint-disable-next-line quote-props
        '425': {
          slidesPerView: el.data('slides-s') || 1,
          spaceBetween: 10,
          pagination: PAGINATION_CLASS
        }
      }
    });
  }
});
