// @flow

import React from 'react';
import AgeRatings from './AgeRatings';

export type Props = {
  mid: string,
  playerTokenPayload: string,
  serieTitle: string,
  title: string,
  description: string,
  serieSlug: string,
  ageRating: string,
  contentRatings: Array<string>
}

const PlayerInfo = (props: Props): any => (
  <div className="u-margin-top-l-down">
    <h2 className="heading heading--white">
      {props.serieTitle}: {props.title}
    </h2>
    <div className="heading heading--xxs heading--white u-margin-top-05">
      <div className="u-pull-right">
        <AgeRatings {...props} />
      </div>
    </div>
    <div className="u-margin-top">
      {props.description}
    </div>
    <a href={props.serieSlug} className="btn btn--primary u-margin-top">
      MEER VAN DIT PROGRAMMA
    </a>
  </div>
);
export default PlayerInfo;
