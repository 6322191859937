/* @flow */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

function wrapModal(content: string): string {
  return `
    <div class='modal'>
      <div class='modal__background' data-behavior='modal-close'></div>
      <div class='modal__box'>
        <div class='modal__close' data-behavior='modal-close'>×</div>
        <div class='modal__content'>${content}</div>
      </div>
    </div>
  `;
}

function insertModal(content: string): void {
  $('body').append(wrapModal(content));
}

function disableScrolling(): void {
  $('html, body').css({
    overflow: 'hidden',
    'min-height': '100%'
  });
}

registerBehavior({
  name: 'modal-open',
  init: (el) => {
    el.on('click', (e) => {
      e.preventDefault();
      $.ajax({
        url: el.attr('href'),
        success: (content) => {
          insertModal(content);
          disableScrolling();
        }
      });
    });
  }
});
