/*
 * @flow
 *
 * Loads an embedded Instagram post. Uses the instagram api to get the embed
 * code for the url specified with [data-instagram-url=URL] where name is
 * defined on the toggle behavior element via data-target=NAME. If
 * [data-instagram-thumbnail="true"] only the post's thumnail is embedded.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'instagram-post',
  init: (el) => {
    $.ajax({
      url: `https://api.instagram.com/oembed?omitscript=true&url=${el.data('instagram-url')}`,
      method: 'GET',
      dataType: 'jsonp',
      success: (result) => {
        if (el.data('instagram-thumbnail')) {
          el.attr('src', result.thumbnail_url);
        } else {
          el.html(result.html);
        }
        window.instgrm.Embeds.process();
      }
    });
  }
});
