/* @flow */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

// Fetches the stream of messages from the server.
function getMessages(url: string): Function {
  return (callback) => {
    $.ajax({
      url,
      dataType: 'html',
      success: callback
    });
  };
}

// Returns a function that updates the enclosed elements html.
function updateMessageBlock(element: any): Function {
  return (html) => {
    element.html(html);
    element.scrollTop($('.js-messages').height());
  };
}

// Turns a regular callback into a polling callback;
function wrapCallbackPoll(callback: Function, request: Function, interval: number): Function {
  const newCallback = (res: any) => {
    callback(res);
    window.setTimeout(() => { request(newCallback); }, interval);
  };
  return newCallback;
}

// Polls the messages url and updates the message block.
function pollMessages(url: string, el: any): void {
  const messageRequest = getMessages(url);
  const callback = wrapCallbackPoll(updateMessageBlock(el), messageRequest, 5000);
  messageRequest(callback);
}

registerBehavior({
  name: 'messages',
  init: (el) => {
    const url = el.data('url');
    pollMessages(url, el);
  }
});
