/* @flow
 *
 * Search for series on each keypress and show the results inline.
 */

import $ from 'jquery';
import { debounce, trim } from 'lodash';
import registerBehavior from '../../utils/registerBehavior';

function autocomplete(q: string, resultsContainer: any): void {
  if (trim(q).length === 0) {
    resultsContainer.html('');
  } else {
    $.ajax({
      url: `/quick-search/?q=${q}`,
      dataType: 'html',
      success: (html) => {
        resultsContainer.html(html);
      }
    });
  }
}

const debouncedAutocomplete = debounce(autocomplete, 400);

registerBehavior({
  name: 'quick-search',
  init: (el) => {
    const resultsContainer = el.find('[data-type=results]');
    el.find('[data-type=input]').on('input', (e) => {
      debouncedAutocomplete(e.currentTarget.value, resultsContainer);
    });
  }
});
