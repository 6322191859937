/* @flow
 *
 * Component that renders a single icon in a rating scale.
 */
import React from 'react';

export default class RatingIcon extends React.Component<any> {
  activate = (e: SyntheticEvent<*>): void => {
    e.preventDefault();
    this.props.activate(this.props.rating.value);
  }

  isActive = (): bool => this.props.rating.value === this.props.selected;

  name = (): string => {
    const { objectName, method } = this.props;
    return `${objectName}[${method}]`;
  }

  id = (): string => {
    const { objectName, method } = this.props;
    return `${objectName}_${method}_${this.props.rating.value}`;
  }

  render(): any {
    const activeClass = this.isActive() ? 'is-active' : '';
    const optionClass = `rating-select__option ${activeClass}`;
    const elementClass = `rating-select__icon ${this.props.rating.iconClass}`;
    return (
      <button className={optionClass} onClick={this.activate}>
        <div className="rating-select__wrapper">
          <div className={elementClass} />
        </div>
        <div className="u-hidden">
          <input
            name={this.name()}
            value={this.props.rating.value}
            id={this.id()}
            type="radio"
            checked={this.isActive()}
          />
        </div>
        <p>{this.props.rating.text}</p>
      </button>
    );
  }
}
