/* @flow
 *
 * Send the search filter to ProgramsController#index.
 */

import $ from 'jquery';
import { debounce } from 'lodash';
import registerBehavior from '../../utils/registerBehavior';

function activateAllVisibleFilter(el: any): void {
  el.find('[data-filter]').removeClass('is-active');
  el.find('[data-filter=alles]').addClass('is-active');
}

// Submit the search query to the backend and regplace the html
// When searching, the all_visible filter will be activated
function searchSeries(event: SyntheticInputEvent<*>, el: any): void {
  $.ajax({
    url: window.location.pathname,
    data: {
      query: event.target.value
    },
    success: (result) => {
      el.find('[data-type=container]').html(result);
      activateAllVisibleFilter(el);
    }
  });
}

registerBehavior({
  name: 'search-and-filter',
  init: (el) => {
    el.find('[data-type=search-field]').on('input',
      debounce(event => searchSeries(event, el), 250),
    );
  }
});
