/* @flow */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

function removeModal(): void {
  $('.modal').remove();
}

function enableScrolling(): void {
  $('html, body').css({
    overflow: 'auto',
    height: 'auto'
  });
}

registerBehavior({
  name: 'modal-close',
  init: (el) => {
    el.on('click', () => {
      removeModal();
      enableScrolling();
    });
  }
});
