// @flow

import { Controller } from 'stimulus';
import { debounce } from 'lodash';

// Controller that can search, update category links and the url bar with
// the query param of the search
export default class extends Controller {

  static targets = ["container", "searchField", "filter", "categoryLink"];

  // Submit the search query to the backend and replace the html
  search(event: SyntheticInputEvent<*>): void {
    let url = new URL(location.protocol + '//' + location.host + '/kinderfilms/zoeken');
    url.searchParams.set("query", this.data.get("query"));
    fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => response.text())
      .then((response) => {
        this.containerTarget.innerHTML = response;
      });
  }

  // Update url bar with the search param (in our case the query param)
  updateUrlBar(value: string): void {
    let url = new URL(window.location.href)
    if (value) {
        url.searchParams.set("query", value);
      } else {
        url.searchParams.delete("query")
    }
    let state = { html: url.href };
    window.history.pushState(state, value, url.pathname + url.search);
  }

  // Update category links urls with search param
  updateCategoryItemsUrlParam(value: string): void {
    for (let item of this.categoryLinkTargets) {
      let url = new URL(item.href);
      if (value) {
        url.searchParams.set("query", value);
      } else {
        url.searchParams.delete("query")
      }
      item.href = url.href;
    }
  }

  connect(): void {
    this.updateCategoryItemsUrlParam(this.searchFieldTarget.value);
    this.searchFieldTarget.addEventListener("input",
      debounce(event => {
        this.data.set("query", this.searchFieldTarget.value);
        this.updateUrlBar(this.searchFieldTarget.value)
        this.search(event)
        this.updateCategoryItemsUrlParam(event.target.value)
      }, 250)
    )
  }

  disconnect(): void {
    this.searchFieldTarget.removeEventListener("input");
  }
}
