// @flow

import React from 'react';

type Props = {
  changeName: Function,
  setName: Function,
};

export default ({ changeName, setName } : Props) => (
  <div className="chat-form">
    <input
      type="text"
      className="chat-form__field"
      name="skills_message_name"
      placeholder="Wat is je naam?"
      onChange={event => changeName(event.target.value)}
    />
    <button className="btn chat-form__btn" onClick={setName}>{''}</button>
  </div>
);
