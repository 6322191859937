// @flow

import { Controller } from "stimulus"

// Pagination for the Zapp home.
// 
// ## Data attributes
//
// totalPages: Total number of pages for pagination
// path: Path to request additional pages
export default class extends Controller {
  static targets = [ "container", "button" ]

  connect() {
    this.currentPage = 1;
    this.totalPages = parseInt(this.data.get("totalPages"));
    this.path = this.data.get("path");
    this.checkHideButton();    
  }

  checkHideButton() {
    if (this.currentPage >= this.totalPages) {
      this.buttonTarget.style.display = "none";
    }
  }

  paginate() {
    let nextPage = this.currentPage + 1;
    fetch(this.path + `?page=${nextPage}`, {
      headers: { 'X-Pagination': 'true' }
    }).then((response) => {
      return response.text();
    }).then((html) => {
      this.currentPage = nextPage;
      this.containerTarget.insertAdjacentHTML("beforeend", html);
      this.checkHideButton();
    });
  }
}
