// @flow

import Cookies from "js-cookie"
import { Controller } from "stimulus"
import { sampleSize } from "lodash"

export default class extends Controller {
  static values = {
    srcTemplate: String
  }

  base62Characters = "0123456789abcdefghijklmopqrstuvwzyxABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
  cookieName = 'embed_token'

  connect() {
    this.element.src = this.src()
  }

  src() {
    return this.srcTemplateValue.replace('EMBED_TOKEN_PLACEHOLDER', this.token())
  }

  token() {
    return Cookies.get(this.cookieName) || this.setToken()
  }

  setToken() {
    let token = sampleSize(this.base62Characters, 24).join('')
    Cookies.set(this.cookieName, token, {expires: 365})
    return token
  }
}
