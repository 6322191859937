/* @flow
 *
 * Adds an `is-ie` class to the <html> tag for pre-Edge IE browsers
 */

import $ from 'jquery';

function isIE(): boolean {
  return navigator.userAgent.indexOf('Trident') !== -1;
}

$(() => {
  if (isIE()) {
    $('html').addClass('is-ie');
  }
});
