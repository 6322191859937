// @flow

import $ from 'jquery';
import React from 'react';
import Message from './Message';
import Name from './Name';

type Props = {
  url: string,
  name: string,
  csrf: string,
}

type State = {
  name: string,
  setName: boolean,
  message: string,
  success: boolean,
};

export default class ChatFormContainer extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      name: this.props.name,
      setName: !!this.props.name,
      message: '',
      success: false
    };
  }

  clearName = (): void => {
    this.setState({ name: '', setName: false });
  }

  changeName = (name: string): void => {
    this.setState({ name });
  }

  setName = (): void => {
    this.setState({ setName: true });
  }

  setMessage = (message: string): void => {
    this.setState({ message });
  }

  success = (): void => {
    this.setState({ message: '', success: true });
    window.setTimeout(() => this.setState({ success: false }), 5000);
  }

  postMessage = (): void => {
    $.ajax({
      url: this.props.url,
      type: 'post',
      data: { skills_message: {
        name: this.state.name,
        body: this.state.message
      } },
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', this.props.csrf);
      },
      success: () => {
        this.success();
      }
    });
  }

  render(): any {
    return (
      <div>
        { this.state.success &&
          <div className="chat__status">Een moment! Je bericht verschijnt na goedkeuring.</div>
        }
        { this.state.name && this.state.setName ?
          <Message
            name={this.state.name}
            message={this.state.message}
            clearName={this.clearName}
            setMessage={this.setMessage}
            postMessage={this.postMessage}
          /> :
          <Name
            setName={this.setName}
            changeName={this.changeName}
          />
        }
      </div>
    );
  }
}
