/* @flow
 *
 * We expose these components so we're able to render them via Rails.
 * The Rails helper `react_behavior` accepts a component name and searches the
 * javascript global namespace if its available.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { camelizeKeys } from 'humps';
import registerBehavior from '../../utils/registerBehavior';
import * as standaloneComponents from '../../../components';

registerBehavior({
  name: 'react',
  init: (el) => {
    const className = el.data('reactClass').toString();
    const props = el.data('reactProps');
    const constructor = standaloneComponents[className];
    ReactDOM.render(React.createElement(constructor, camelizeKeys(props)), el[0]);
  }
});
