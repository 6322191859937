// @flow

import React from 'react';
import { motion } from 'framer-motion'
import classnames from 'classnames';
import type {
  Candidate as CandidateType,
  HandleToggleModal,
  HandleSelect
} from './types';

type Props = {|
  handleToggleModal: HandleToggleModal,
  handleSelect: HandleSelect,
  candidate: CandidateType,
  selected: boolean,
|};

const Candidate = (props: Props) => {
  const animations = {
    layout: true,
  }
  return <motion.div className="l-col-3-l l-col-4-m l-col-6-s"
    {...animations}
    >
    <div className="card">
      <div className="card__image" onClick={() => props.handleToggleModal(props.candidate.mid, props.candidate.playerModalPlayerToken)}>
        <div className="">
          <div className={classnames('overlay', { 'overlay--green': props.selected })}>
            {props.selected &&
              <div className="overlay__background">
                <div className="overlay__content">
                  <div className="icon icon--checkmark icon--xl" />
                </div>
              </div>}
            <div className="ratio ratio--16x9">
              <img src={props.candidate.imageUrl} alt="optie" />
            </div>
          </div>
        </div>
      </div>
      {props.candidate.mid && !props.selected &&
        <div className="card-icon card-icon--award" onClick={() => props.handleToggleModal(props.candidate.mid, props.candidate.playerModalPlayerToken)}>
          <i className="fa fa-play" />
        </div>
      }
      <div className="card-content-with-btn">
        <div className="card-content-with-btn__content">
          <div className="heading heading--xxs">{props.candidate.title}</div>
        </div>
        <div className="card-content-with-btn__btn">
          {!props.selected &&
            <button className="btn btn--primary btn--s" onClick={() => props.handleSelect(props.candidate.categoryId, props.candidate.id)}>
              Kies
            </button>}
        </div>
      </div>
    </div>
  </motion.div>
};

export default Candidate;
