/* @flow
 *
 * Calculate the total width of all the items in the data-type=container.
 * If these items fit within the element, nothing happens. When it doesn't
 * fit, the `show-mobile` css class is added to the element.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

function getMenuWidth(el: any): number {
  return el.find('[data-type=container]').children().toArray().reduce((acc, cur) => acc + $(cur).outerWidth(), 0);
}

function toggle(el: any, menuWidth: number): void {
  const width = el.width();
  const showMobile = menuWidth > width;
  el.toggleClass('show-mobile', showMobile);
}

registerBehavior({
  name: 'tabs',
  init: (el) => {
    const menuWidth = getMenuWidth(el);
    toggle(el, menuWidth);
    $(window).on('resize', () => {
      toggle(el, menuWidth);
    });
  }
});
