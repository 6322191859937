/* @flow */
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';
import "@stimulus/polyfills";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage"

import '../application/initializers/index';
import '../application/behavior/index';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

if (!Element.prototype.matches) {
  //$FlowFixMe
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

Rails.start();
ActiveStorage.start()
const application = Application.start()
const context = (require: any).context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
