/* @flow
 *
 * Send the search filter to ProgramsController#index.
 */

import $ from 'jquery';
import { debounce } from 'lodash';
import registerBehavior from '../../utils/registerBehavior';

// Submit the search query to the backend and regplace the html
// When searching, the all_visible filter will be activated
function showSeason(event: SyntheticInputEvent<*>, el: any): void {
  const mid = event.target.value;
  $.ajax({
    url: window.location.pathname,
    data: { season: mid },
    success: (result) => {
      el.find('[data-type=search-container]').html(result);
    }
  });
}

registerBehavior({
  name: 'season-filter',
  init: (el) => {
    el.on('change', '[data-type=season-filter-search]',
      debounce(event => showSeason(event, el), 250),
    );
  }
});
