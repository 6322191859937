// @flow

import React from 'react';

type Props = {
  name: string,
  message: string,
  clearName: Function,
  setMessage: Function,
  postMessage: Function,
};

export default ({ name, message, clearName, setMessage, postMessage }: Props) => (
  <div>
    <div className="chat__status">
      {`Je chat als ${name}`}
      <a className="u-pull-right" onClick={clearName}>Chat als iemand anders</a>
    </div>
    <div className="chat-form">
      <input
        type="text"
        className="chat-form__field"
        name="skills_message_body"
        placeholder="Zeg iets..."
        value={message}
        onChange={event => setMessage(event.target.value)}
      />
      <button className="btn chat-form__btn" onClick={postMessage}>{''}</button>
    </div>
  </div>
);
