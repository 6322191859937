/* @flow
 *
 * Automatically updates the content of an element. [data-interval=SECONDS] is
 * used to specify how often the element is updated. [data-path=PATH] provides
 * the path from which the updated content is repeatedly requested.
 */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

function updateContent(el: any, path: string, interval: number): void {
  $.ajax({
    url: path,
    success: (content) => {
      el.html(content);
    }
  }).always(() => {
    window.setTimeout(() => { updateContent(el, path, interval); }, interval);
  });
}

registerBehavior({
  name: 'auto-update',
  init: (el) => {
    updateContent(el, el.data('path'), el.data('interval') * 1000);
  }
});
