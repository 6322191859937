// @flow

import { Controller } from "stimulus"
import _ from "lodash";

export default class extends Controller {
  static targets = [ "menu", "page", "body" ];

  connect() {
    window.addEventListener('scroll', _.throttle((e) => this.checkHeader(e), 100));
  }

  checkHeader(e: Event) {
    if (this.bodyTarget.getBoundingClientRect().top < 0) {
      this.menuTarget.classList.add("is-fixed");
      this.menuTarget.classList.add("is-small");
    } else {
      this.menuTarget.classList.remove("is-fixed");
      this.menuTarget.classList.remove("is-small");
    }
    if ((this.hasPageTarget && this.pageTarget.dataset.transparent) ||
        (this.hasBodyTarget && this.bodyTarget.dataset.transparent)) {
      if (this.bodyTarget.getBoundingClientRect().top < -30) {
        this.menuTarget.classList.add("is-opaque")
        this.menuTarget.classList.add("is-dark");
      } else {
        this.menuTarget.classList.remove("is-opaque");
        this.menuTarget.classList.remove("is-dark");
      }
    }
  }
}
