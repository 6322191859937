// @flow
import React from 'react';
import Player from '.';

type Props = {|
  mid: string,
  playerTokenPayload: string,
  onClose: () => void,
|};

type PlayerWrapperDimensions = {
  top: number,
  right: number,
  bottom: number,
  left: number,
}

type State = {
  playerWrapperDimensions: PlayerWrapperDimensions
}

class PlayerModal extends React.Component<Props, State> {
  outerPlayerWrapper: any;
  innerPlayerWrapper: any;

  constructor(props: Props): void {
    super(props);
    this.state = {
      playerWrapperDimensions: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    };
  }

  render(): any {
    const { mid, onClose, playerTokenPayload } = this.props;
    if (!this.props.mid) return null;
    return (
      <div className="player-modal l-full-screen-overlay l-full-screen-over-menu">
        <div className="player-modal__close-wrapper">
          <div className="player-modal__close" onClick={onClose} />
        </div>
        <div
          className="player-modal__outer-player-wrapper"
          ref={(node) => { this.outerPlayerWrapper = node; }}
        >
          <div
            className="player-modal__inner-player-wrapper"
            ref={(node) => { this.innerPlayerWrapper = node; }}
            style={this.state.playerWrapperDimensions}
          >
            <Player playerTokenPayload={playerTokenPayload} mid={mid} autoplay={true} />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    window.addEventListener('resize', this.onResize, false);
    this.onResize();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    if (!this.props.mid) return;
    const { width, height } = this.outerPlayerWrapper.getBoundingClientRect();
    const verticalMargin = Math.max(height - (width / (16 / 9)), 0);
    const horizontalMargin = Math.max(width - (height * (16 / 9)), 0);
    this.setState({ playerWrapperDimensions: {
      top: verticalMargin / 2,
      right: horizontalMargin / 2,
      bottom: verticalMargin / 2,
      left: horizontalMargin / 2
    } });
  }
}

export default PlayerModal;
