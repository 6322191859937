// @flow

import React from 'react';
import type { HandleSelect } from './types';

type Props = {|
  categoryId: number,
  handleSelect: HandleSelect,
  selection: string,
|};

class FreeInput extends React.Component<Props, { text: string }> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      text: props.selection
    };
  }

  componentWillReceiveProps(nextProps: Props): void {
    this.setState({
      text: nextProps.selection
    });
  }

  onChange = (e: Event): void => {
    if (e.target instanceof HTMLInputElement) {
      this.setState({
        text: e.target.value
      });
    }
  }

  onSelect = (): void => {
    if (this.state.text.length > 3) {
      this.props.handleSelect(this.props.categoryId, this.state.text);
    }
  }

  render(): any {
    return (
      <div className="u-border-top u-margin-top u-centered">
        <div className="l-row u-margin-top">
          <div className="l-col-4-m u-hidden-m-down" />
          <div className="l-col-4-m">
            <div className="heading heading--xs">
              Staat jouw keuze er niet tussen?
            </div>
            <div className="heading heading--xs heading--grey">
              Vul dan hier jouw keuze in!
            </div>
            <input
              type="text"
              placeholder="Naam van eigen inzending"
              className="form-input u-margin-top"
              value={this.state.text}
              onChange={this.onChange}
            />
            <div className="btn btn--primary btn--s u-margin-top-05" onClick={this.onSelect}>
              Kies
            </div>
          </div>
          <div className="l-col-4-m u-hidden-m-down" />
        </div>
      </div>
    );
  }
}

export default FreeInput;
