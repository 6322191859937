// @flow

import $ from 'jquery';
import React from 'react';
import { decamelizeKeys } from 'humps';
import { merge, shuffle } from 'lodash';
import AwardsEvent from './AwardsEvent';
import Thanks from './Voted';
import type {
  Category,
  Page,
  HandleConfirm,
  HandleGoToPage,
  HandleToggleModal,
  HandleNextPage,
  HandleSelect,
  Selection,
  ServerAwardsEvent
} from './types';

type ServerVote = {
  categoryId: number,
  candidateId?: number,
  text?: string,
}

const INTRO_PAGE = { template: 'Intro', title: 'Introductie' };
const CONFIRM_PAGE = { template: 'Confirm', title: 'Bevestigen' };

function buildServerVote(selection: Selection): ServerVote[] {
  return Object.keys(selection).map((categoryId) => {
    const input = selection[Number(categoryId)];
    if (typeof input === 'string') {
      return { categoryId: Number(categoryId), text: input };
    }
    return { categoryId: Number(categoryId), candidateId: input };
  });
}

function shuffleCandidates(categories: Category[]): Category[] {
  return categories.map(category => ({
    ...category,
    candidates: shuffle(category.candidates)
  }));
}


function nextPageIndex(selection: Selection): number {
  return Object.keys(selection).length + 1;
}

type State = {
  currentPageIndex: number,
  selection: Selection,
  pages: Page[],
  playerModalMid: string,
  playerModalPlayerToken: string,
  usedEmail: boolean,
  query: string,
  voted: boolean,
};

class AwardsEventContainer extends React.Component<ServerAwardsEvent, State> {
  constructor(props: ServerAwardsEvent): void {
    super(props);
    this.state = {
      currentPageIndex: 0,
      selection: {},
      pages: [INTRO_PAGE, ...shuffleCandidates(props.categories), CONFIRM_PAGE],
      playerModalMid: '',
      playerModalPlayerToken: '',
      usedEmail: false,
      query: '',
      voted: false,
    };
  }

  handleNextPage: HandleNextPage = () => {
    this.handleGoToPage(nextPageIndex(this.state.selection));
  };

  handleGoToPage: HandleGoToPage = (i) => {
    this.setState({
      currentPageIndex: i,
      query: '',
    });
    window.scrollTo(0, 0);
  };

  handleSelect: HandleSelect = (categoryId, input) => {
    const selection = {
      ...this.state.selection,
      [categoryId]: input
    };

    this.setState({
      currentPageIndex: nextPageIndex(selection),
      query: '',
      selection
    });

    window.scrollTo(0, 0);
  };

  handleToggleModal: HandleToggleModal = (mid, playerModalPlayerToken) => {
    this.setState({ playerModalMid: mid, playerModalPlayerToken: playerModalPlayerToken});
  };

  handleConfirm: HandleConfirm = (email) => {
    const csrfTag = document.querySelector('meta[name=csrf-token]')
    const csrfToken = csrfTag ? (csrfTag : any).content : "";
    $.ajax({
      url: `/programmas/${this.props.serieSlug}/awards/events/${this.props.id}/votes`,
      contentType: 'application/json',
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken
      },
      data: JSON.stringify(decamelizeKeys({
        vote: {
          categoryVotes: buildServerVote(this.state.selection),
          email
        }
      })),
      success: () => {
        this.setState({ voted: true })
      },
      error: () => {
        this.setState({ usedEmail: true });
      }
    });
  };

  setQuery = (query: string) => {
    this.setState({query: query})
  }

  render(): any {
    return (
      this.state.voted ? <Thanks /> : <AwardsEvent
        handleNextPage={this.handleNextPage}
        handleGoToPage={this.handleGoToPage}
        handleToggleModal={this.handleToggleModal}
        handleSelect={this.handleSelect}
        handleConfirm={this.handleConfirm}
        setQuery={this.setQuery}
        {...merge({}, this.state, this.props)}
      />
    );
  }
}

export default AwardsEventContainer;
