/* @flow
 *
 * Use a swiper for steps on skills
 * Documentation: http://idangero.us/swiper/api/
 */

import Swiper from 'swiper';
import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'swiper',
  init: (el) => {
    // eslint-disable-next-line no-new
    new Swiper(el, {
      slidesPerView: 3,
      spaceBetween: 30,
      paginationClickable: true,
      autoHeight: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      breakpoints: {
        // eslint-disable-next-line quote-props
        '1024': {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // eslint-disable-next-line quote-props
        '425': {
          slidesPerView: 1,
          spaceBetween: 0,
          pagination: '.swiper-pagination'
        }
      }
    });
  }
});
