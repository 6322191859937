/* @flow
 *
 * Container for a photoalbum with optional upload form. This is the entrypoint
 * that can be rendered in a view that needs this component. It defines the
 * default state and props that can and must be provided.
 */

import React from 'react';
import { merge } from 'lodash';
import PhotoAlbum from './PhotoAlbum';
import type {
  ServerPhotoAlbum,
  State
} from './types';

export default class PhotoAlbumContainer extends React.Component<ServerPhotoAlbum, State> {
  constructor(props: ServerPhotoAlbum): void {
    super(props);
    this.state = {
      currentPhotoIndex: 0,
      currentDate: props.photos.slice(-1)[0].uploadDate
    };
  }

  next = () => {
    this.setState({
      currentPhotoIndex: (this.state.currentPhotoIndex + 1) % this.props.photos.length
    });
  };

  previous = () => {
    const newIndex = this.state.currentPhotoIndex - 1;
    this.setState({
      currentPhotoIndex: newIndex < 0 ? this.props.photos.length - 1 : newIndex
    });
  };

  render(): any {
    const actions = {
      next: this.next,
      previous: this.previous,
      setDate: date => this.setState({ currentPhotoIndex: 0, currentDate: date }),
      selectPhoto: i => this.setState({ currentPhotoIndex: i }),
    };

    return (
      <PhotoAlbum
        {...merge({}, this.props, this.state)}
        actions={actions}
      />
    );
  }
}
