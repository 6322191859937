/* @flow
 *
 * A caroussel that displays a list of photos. One of the is show large, the
 * others as thumbnails. There are arrow buttons which loop through the whole
 * list and then start over. The thumbnails can also be clicked to display
 * the selected photo.
*/

import React from 'react';
import type { Photo, PhotoAlbum } from './types';
import { TransitionGroup, Transition } from 'react-transition-group';
import { filter, map, uniq } from 'lodash';

type HeroProps = {
  currentPhoto: Photo,
};

const filterPhotos = (photos, currentDate, filterableByDate) => {
  if(filterableByDate){
    return filter(photos, {uploadDate: currentDate})
  } else {
    return photos
  }
}

const Hero = (props: HeroProps) => (
  <div className="l-col-12-m l-col-12">
    <span
       className={`compact-item compact-item--no-click photo-album__animation`}>
      <div className="">
        <div className="ratio ratio--16x9">
          <img className='u-hidden-s-down'
            alt={props.currentPhoto.title}
            src={props.currentPhoto.heroUrl}
          />
          <img className='u-hidden-s'
            alt={props.currentPhoto.title}
            src={props.currentPhoto.heroMobileUrl}
          />
        </div>
      </div>
      <div className="compact-item__title">
        <div className="heading heading--white heading--xs">
          {props.currentPhoto.title}
        </div>
      </div>
    </span>
  </div>
);

type ThumbnailProps = {
  index: number,
  photo: Photo,
  onClick: Function,
  isActive: boolean,
};

const Thumbnail = (props: ThumbnailProps) => (
  <div className="l-col-3-m l-col-4">
    <div
      className={`compact-item ${props.isActive ? 'is-active' : ''}`}
      onClick={() => props.onClick(props.index)}
    >
      <div className="compact-item__image">
        <div className="ratio ratio--16x9">
          <img
            alt={props.photo.title}
            src={props.photo.thumbnailUrl}
          />
        </div>
      </div>
    </div>
  </div>
);

const Carousel = (props: PhotoAlbum) => {
  const filteredPhotos = filterPhotos(props.photos, props.currentDate, props.filterableByDate)
  const currentPhoto = filteredPhotos[props.currentPhotoIndex];

  if (!currentPhoto) {
    return <div />;
  }

  const dates = uniq(map(props.photos, 'uploadDate'));
  const windowWidthModulo = window.innerWidth <= 750 ? 3 : 4;
  const locationPopup = props.currentPhotoIndex % windowWidthModulo;
  const widthInc = 100 / windowWidthModulo;
  return (
    <div>
      <div className='overflow-hidden'>
        {props.title && <h2 className="heading heading--s u-pull-left u-padding-bottom">{props.title}</h2>}
        {props.filterableByDate &&
          <select className='select u-pull-right' value={props.currentDate} onChange={e => props.actions.setDate(e.currentTarget.value)}>
            {dates.map((date)=>
              <option key={date}>{date}</option>
              )
            }
          </select>
        }
      </div>
      <div className="u-margin-top l-row l-row--grid">
        {filteredPhotos.map((photo, i) =>
          <React.Fragment key={photo.id}>
            {i + (props.currentPhotoIndex % windowWidthModulo) === props.currentPhotoIndex &&
              <Hero currentPhoto={currentPhoto} />}
            <Thumbnail
              photo={photo}
              index={i}
              isActive={currentPhoto === photo}
              onClick={props.actions.selectPhoto}
            />
          </React.Fragment>
          )
        }
      </div>
      <div className="u-margin-top">
        {props.userUpload &&
          <a
            className="btn btn--grey u-pull-right"
            href={props.newPhotoPath}
          >
            Upload je eigen foto
          </a>}
        {props.showAll || <a href={props.url} className="btn btn--primary">Bekijk alles</a>}
      </div>
    </div>
  );
};

export default Carousel;
