// @flow

import React from 'react';
import Player from '../Player';
import PlayerInfo, { type Props as PlayerInfoProps } from './PlayerInfo';

type State = {
  currentClip: PlayerInfoProps
}

type Props = {
  playlist: Array<PlayerInfoProps>
}

class PlaylistInfoPlayer extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      currentClip: props.playlist[0]
    };
  }

  nextVideo = () => {
    this.setState((prevState: State) => {
      const { playlist } = this.props;
      const oldIndex = playlist.indexOf(prevState.currentClip);
      const index = oldIndex >= playlist.length ? 0 : oldIndex + 1;
      return { currentClip: this.props.playlist[index] };
    });
  }

  render(): React$Element<'div'> {
    const currentClip = this.state.currentClip;

    return (
      <div className="l-row">
        <div className="l-col-8-l">
          <div className="player">
            <div className="player__frame">
              <Player playerTokenPayload={currentClip.playerTokenPayload} mid={currentClip.mid} onComplete={this.nextVideo} autoplay={true} />
            </div>
          </div>
        </div>
        <div className="l-col-4-l">
          <PlayerInfo {...currentClip} />
        </div>
      </div>
    );
  }
}

export default PlaylistInfoPlayer;
