/* @flow
 *
 * Wrapper for the photo caroussel and upload form.
 */

import React from 'react';
import Carousel from './Carousel';
import type { PhotoAlbum as PhotoAlbumType } from './types';

const PhotoAlbum = (props: PhotoAlbumType) => (
  <div>
    {<Carousel {...props} />}
  </div>
);

export default PhotoAlbum;
