/* @flow */

import $ from 'jquery';
import registerBehavior from '../../utils/registerBehavior';

function showFlashMessage(className: string, message: string): void {
  $(`<div class='message ${className}'>${message}</div>`).insertAfter('.header');
}

registerBehavior({
  name: 'downvote',
  init: (el) => {
    el
      .on('ajax:beforeSend', () => {
        $('.message').remove();
      })
      .on('ajax:error', (e) => {
        showFlashMessage('alert', 'Je stem is niet verstuurd. Probeer het opnieuw.');
        $(e.target).show();
      }).on('ajax:success', (e) => {
        showFlashMessage('notice', 'We hebben je stem ontvangen.');
        $(e.target).remove();
      });
  }
});
