// @flow

import React from 'react';
import classnames from 'classnames';

type Props = {
  index: number,
  currentIndex: number,
  maxAllowedIndex: number,
  title: string,
  onClick: Function,
}

function clickable(props: Props): boolean {
  return props.maxAllowedIndex >= props.index;
}

function className(props: Props): string {
  return classnames('steps-item', {
    'is-active': props.index === props.currentIndex,
    'is-done': props.index < props.maxAllowedIndex,
    'is-before': props.index < props.currentIndex,
    'is-clickable': clickable(props)
  });
}

const AwardsStepsItem = (props: Props) => (
  <div className={className(props)} onClick={() => (clickable(props) ? props.onClick() : null)}>
    <div className="steps-item__dot">
      {props.index + 1}
    </div>
    <div className="steps-item__title">
      {props.title}
    </div>
  </div>
);

export default AwardsStepsItem;
