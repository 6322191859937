/* @flow
 *
 * Register a dom behavior by proving a name and a start callback. This
 * module makes sure that the callback will be called on all visible dom
 * elements, even those which are displayed via an ajax call.
 */

import $ from 'jquery';

type Options = {
  name: string,
  init: Function,
}

function createHandler(options: Options): Function {
  return () => {
    $(`[data-behavior=${options.name}]:not([data-started])`).each((i, el) => {
      const $el = $(el);
      $el.attr('data-started', '');
      options.init($el);
    });
  };
}

export default (options: Options): void => {
  const handler = createHandler(options);
  $(document)
    .ready(handler)
    .on('pjax:complete ajaxComplete', handler);
};
