// @flow

import React from 'react';
import Player from '../Player';
import type { AwardsEvent } from './types';

type IntroPlayerProps = {
  mid: string,
  playerTokenPayload: string
}

const IntroPlayer = (props: IntroPlayerProps) => {
  if (props.mid === '') {
    return null
  }
  return (
    <div className="player">
      <div className="player__frame">
        <Player playerTokenPayload={props.playerTokenPayload} mid={props.mid} autoPlay />
      </div>
    </div>
  );
};

const Intro = ({ awardsEvent }: { awardsEvent: AwardsEvent }) => (
  <div>
    <IntroPlayer playerTokenPayload={awardsEvent.introPagePlayerTokenPayload} mid={awardsEvent.introPageMid} />
    <div className="l-row u-margin-top">
      <div className="l-col-12-m u-hidden-s-down u-padding-bottom">
        <img className="w-full" src={awardsEvent.desktopHeaderImageUrl} />
      </div>
      <img className="w-full u-hidden-s u-padding-bottom" src={awardsEvent.mobileHeaderImageUrl} />
      <div className="l-col-9-m">
        <h1 className="heading">{awardsEvent.title}</h1>
        <p className="u-margin-top" dangerouslySetInnerHTML={{ __html: awardsEvent.introPageText }} />
      </div>
      <div className="l-col-3-m">
        <button className="btn btn--primary u-pull-right u-margin-top-m-down" onClick={awardsEvent.handleNextPage}>
          Laten we beginnen!
        </button>
      </div>
    </div>
  </div>
);

export default Intro;
