/*
  Behavior to set the amount of a progress bar depending on the mid and duration
  data attributes.
  Needs data-mid and data-duration as attributes.
*/

import registerBehavior from '../../utils/registerBehavior';

registerBehavior({
  name: 'video-progress',
  init: (el) => {
    const bar = el[0];
    const mid = el.attr('data-mid');
    if (bar) {
      const progressObject = localStorage.getItem('zapp_progress');
      let currentProgress = null;
      if (progressObject) {
        currentProgress = JSON.parse(progressObject).find(item => item.mid === mid);
      }
      if (currentProgress) {
        bar.style.width = `${currentProgress.ratio}%`;
      }
    }
  }
});
