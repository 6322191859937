/* @flow
 *
 * Component that shows five images and allows you to select them like a radio
 * button.
 */
import React from 'react';
import RatingIcon from './RatingIcon';

type Rating = {
  value: number,
  iconClass: string,
  text: string,
}

type Props = {
  ratings: Array<Rating>,
  objectName: string,
  method: string,
}

type State = {
  selected: ?number,
}

export default class RatingSelect extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      selected: null
    };
  }

  activate = (id:number): void => {
    this.setState({
      selected: id
    });
  }

  render(): any {
    const ratings = this.props.ratings.map(rating => (<RatingIcon
      key={rating.value}
      selected={this.state.selected}
      activate={this.activate}
      rating={rating}
      objectName={this.props.objectName}
      method={this.props.method}
    />));
    return (
      <div className="rating-select__answer">
        {ratings}
      </div>
    );
  }
}
