// This controls the flow on Waku Waku daily contest page

$( document ).ready(function() {
  const contestId = $('#question-form').data('contest-id');
  const correctAnswer = $('#question-form').data('answer');
  const mid = $('#question-form').data('mid');
  const secondaryMid = $('#question-form').data('secondary-mid');
  const localStorageAnswer = localStorage.getItem(`waku_waku_${contestId}`);
  let isUserCorrect;

  // if user has already participated, show the correct form
  if (localStorageAnswer === 'true' || localStorageAnswer === 'false') {
    $('#question-form').addClass('hidden');
    $('#correct-form').addClass('hidden');
    $('#wrong-form').removeClass('hidden');
  }

  // selecting an answer
  $('.waku-waku-button.answer').on('click', function() {
    // save stuff is user is correct or wrong

    if ($(this).data('answer') === correctAnswer) {
      isUserCorrect = true;
      !localStorageAnswer && localStorage.setItem(`waku_waku_${contestId}`, 'true');
    } else {
      $(this).addClass('wrong');
      isUserCorrect = false;
      !localStorageAnswer && localStorage.setItem(`waku_waku_${contestId}`, 'false');
    }

    // overwrite the current result if the user has already participated before
    if (localStorageAnswer === 'true') {
      isUserCorrect = true;
    } else if (localStorageAnswer === 'false') {
      isUserCorrect = false;
    }

    // disable all answers
    $('.waku-waku-button.answer').addClass('disabled');

    // highlight correct answer
    $(`.waku-waku-button.answer[data-answer="${correctAnswer}"]`).addClass('correct');

    // show #next-button
    $('#next-button').removeClass('hidden');

  });

  // next button
  $('#next-button').on('click', function() {
    // hide #question-form
    $('#question-form').addClass('hidden');
    window.dispatchEvent(new CustomEvent('stopVideoEvent', {detail: {mid: mid}}));

    // if user is correct, show #correct-form, otherwise show #wrong-form
    if (isUserCorrect) {
      $('#correct-form').removeClass('hidden');

      window.dispatchEvent(new CustomEvent('startVideoEvent', {detail: {mid: secondaryMid}}));
    } else {
      $('#wrong-form').removeClass('hidden');
    }
  });
});
