// @flow

import React from 'react';
import AwardsStepsItem from './AwardsStepsItem';
import type { Page, HandleGoToPage } from './types';

type Props = {
  pages: Page[],
  currentPageIndex: number,
  maxAllowedPageIndex: number,
  handleGoToPage: HandleGoToPage,
};

const AwardsSteps = (props: Props) => (
  <div className="steps">
    <div className="steps__items">
      {props.pages.map((page, i) => (
        <AwardsStepsItem
          key={i}
          title={page.title}
          index={i}
          currentIndex={props.currentPageIndex}
          maxAllowedIndex={props.maxAllowedPageIndex}
          onClick={() => props.handleGoToPage(i)}
        />
      ))}
    </div>
  </div>
);

export default AwardsSteps;
