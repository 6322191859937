// @flow

import React, { useState } from 'react';
import { motion, AnimatePresence, AnimateSharedLayout, useEffect } from "framer-motion"
import Candidate from './Candidate';
import FreeInput from './FreeInput';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import close from '@fortawesome/fontawesome-free-solid/faTimesCircle';
import { compareTwoStrings } from 'string-similarity';
import { sortBy } from 'lodash';

import type {
  AwardsEvent,
  Category as CategoryType
} from './types';

type Props = {
  awardsEvent: AwardsEvent,
  page: CategoryType,
}

const filterCandidates = (candidates, query) => {
  if (query === '') {
    return candidates
  }
  return sortBy(candidates, c => -compareTwoStrings(c.title, query.toLowerCase()))
}

const Category = (props: Props) => {
  const query = props.awardsEvent.query;
  const selection = props.awardsEvent.selection[props.page.id];
  const filteredCandidates = filterCandidates(props.page.candidates, query);

  return (
    <div>
      <div className="l-row space-y-1 space-y-0-m">
        <div className="l-col-6-m">
          <h1 className="heading">{props.page.title}</h1>
          <h2 className="heading heading--grey heading--s">Kies 1 van de {props.page.candidates.length} genomineerden</h2>
        </div>
        <div className="l-col-6-m">
          <div className="search-field search-field--sm">
            <input value={query} onChange={e => props.awardsEvent.setQuery(e.currentTarget.value)} placeholder="Zoeken" className="search-field__input" />
            <AnimatePresence>
              {query !== '' && <motion.button className="search-field__clear" onClick={e => props.awardsEvent.setQuery('')} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                <FontAwesomeIcon icon={close} />
              </motion.button>}
            </AnimatePresence>
          </div>
        </div>
      </div>
      
      
      <div className="l-row l-row--left l-row--grid u-margin-top-05">
        <AnimateSharedLayout>
          {filteredCandidates.length > 0 ? filteredCandidates.map(candidate => (
            <AnimatePresence key={candidate.id}>
              <Candidate
                candidate={candidate}
                handleToggleModal={props.awardsEvent.handleToggleModal}
                handleSelect={props.awardsEvent.handleSelect}
                selected={candidate.id === selection}
              />
            </AnimatePresence>
          )) : <div className="margin-y-3 heading heading--grey w-full u-centered">
                Geen kandidaten gevonden
            </div>}
        </AnimateSharedLayout>
      </div>
      {
        props.page.freeInput &&
        <FreeInput
          categoryId={Number(props.page.id)}
          handleSelect={props.awardsEvent.handleSelect}
          selection={typeof selection === 'string' ? selection : ''}
        />
      }
    </div>
  );
};

export default Category;
