// @flow

import React from 'react';
import { find, includes } from 'lodash';
import type {
  AwardsEvent,
  Category,
  Selection
} from './types';

type Props = {
  awardsEvent: AwardsEvent
}

type ItemProps = {
  awardsEvent: AwardsEvent,
  category: Category,
  selection: Selection,
  onChange(): void,
}

function isButtonDisabled(parentalApproval: boolean, email: string, submitted: boolean): boolean {
  return submitted || !parentalApproval || !includes(email, '@') || email.length < 3;
}

const Item = (props: ItemProps) => {
  const input = props.selection[props.category.id];
  const candidate = find(props.category.candidates, c => c.id === input);
  const label = candidate ? candidate.title : input;
  const image = candidate ? candidate.imageUrl : props.awardsEvent.freeInputImageUrl;

  return (
    <div className="card">
      <div className="card__image">
        <div className="ratio ratio--16x9">
          {image && <img src={image} alt="optie" />}
        </div>
      </div>
      <div className="card-content-with-btn">
        <div className="card-content-with-btn__content">
          <div className="heading heading--xxs heading--grey">{props.category.title}</div>
          <div className="heading heading--xxs">{label}</div>
        </div>
        <div className="card-content-with-btn__btn">
          <div className="btn btn--grey btn--s" onClick={props.onChange}>
            Wijzig
          </div>
        </div>
      </div>
    </div>
  );
};

type State = { email: string, parentalApproval: boolean, submitted: boolean};

class Confirm extends React.Component<Props, State> {
  constructor(props: Props): void {
    super(props);
    this.state = {
      submitted: false,
      email: '',
      parentalApproval: false
    };
  }

  render(): any {
    const { awardsEvent } = this.props;
    return (
      <div>
        <h1 className="heading heading--s">Je hebt gekozen voor:</h1>
        <div className="u-margin-top">
          <div className="l-row l-row--grid">
            {awardsEvent.categories.map((category, i) => (
              <div
                className="l-col-4-m l-col-6-s"
                key={category.id}
              >
                <Item
                  awardsEvent={awardsEvent}
                  category={category}
                  selection={awardsEvent.selection}
                  onChange={() => awardsEvent.handleGoToPage(i + 1)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="u-centered u-margin-top">
          <div className="heading heading--xs">
            E-mailadres
          </div>
          <div className="heading--grey">
            Dit gebruiken we om je stem te bevestigen
          </div>
          <div>
            <input
              style={{ maxWidth: '400px' }}
              type="email"
              placeholder="e-mail@adres.nl"
              className="form-input u-margin-top-05"
              name="awards-event-email"
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
          </div>
          <div className="u-margin-top-05">
            <input
              type="checkbox"
              id="awards-event-parental-approval"
              checked={this.state.parentalApproval}
              onChange={() => {
                this.setState(oldState => ({
                  parentalApproval: !oldState.parentalApproval
                }));
              }}
            />
            <label htmlFor="awards-event-parental-approval">
              Mijn ouders/verzorgers weten dat ik dit formulier invul en zijn daarmee akkoord.
            </label>
          </div>
          {awardsEvent.usedEmail &&
            <div className="u-margin-top-05" style={{ color: 'red' }}>
              Dit e-mailadres is al gebruikt om te stemmen
            </div>}
          <button
            className="btn btn--primary u-margin-top"
            disabled={isButtonDisabled(this.state.parentalApproval, this.state.email, this.state.submitted)}
            onClick={() => {
              this.setState({submitted: true});
              awardsEvent.handleConfirm(this.state.email)
            }}
          >
            Stemmen!
          </button>
        </div>
      </div>
    );
  }
}

export default Confirm;
